.AnimatedGlow {
    padding: 20px;
    background: linear-gradient(to right, rgb(254 2 254), rgb(254 143 1));
    box-shadow: inset #101014 0px 0px 10px 17.5px;
    overflow: hidden;
    margin: -5px;
}

.LoadingCircle {
    animation: LoadingCircle 2s infinite;
    animation-timing-function: linear;
}
  
@keyframes LoadingCircle {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}  